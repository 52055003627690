"use client";

import {
  ReactNode,
  FC,
  useState,
  useCallback,
  useEffect,
} from "react";
import { usePostHog } from "posthog-js/react";

import { useNewFormsServiceStore } from "@./state";
import { DisplayState } from "@/constants/state";
import { ISummaryItem, ISummaryDiscountItem } from "@forms/schema";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Table from "apps/website/components/feature/Table/Table";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

import IconButton from "../../../base/IconButton/IconButton";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";

export interface ISummaryDetailed {
  title: string;
  image?: IImageProps;
  items: (ISummaryItem | ISummaryDiscountItem)[];
  footer?: ISummaryItem[];
  children?: ReactNode;
  state: DisplayState;
  reloadSummary: () => void;
  flowSlug: string;
}

const cellStyle = "px-2 py-2 lg:py-2";
const leftCellStyle = `text-left w-[66.666%] ${cellStyle}`;
const rightCellStyle = `text-right ${cellStyle} flex flex-col justify-end`;

declare global {
  interface Window {
    posthog: any; // eslint-disable-line
  }
}

export type TPriceFramingGroup = "test" | "control";
export type TFeatureFlags = Record<string, TPriceFramingGroup>;

const PRICE_FRAMING_HEADER_THEME_MAP: Record<TPriceFramingGroup, Theme> = {
  control: "dark",
  test: "brand",
};

const PRICE_FRAMING_FOOTER_THEME_MAP: Record<TPriceFramingGroup, Theme> = {
  control: "light",
  test: "green",
};

const SummaryDetailed: FC<ISummaryDetailed> = ({
  title,
  image,
  items,
  footer,
  children,
  state,
  reloadSummary,
  flowSlug,
}) => {
  const footerCellStyle = "pb-1";

  const posthog = usePostHog();

  const [ priceFramingExperimentGroup, setPriceFramingExperimentGroup ] = useState<TPriceFramingGroup>("control");

  useEffect(() => {
    const featureFlags: TFeatureFlags = posthog.featureFlags.getFlagVariants() as TFeatureFlags;

    setPriceFramingExperimentGroup(featureFlags["price-framing"] || "control");

  }, [ posthog.featureFlags ]);

  return (
    <div data-component={SummaryDetailed.name}>
      { image?.src && (
        <Image image={image} alt={title} />
      ) }
      <Table className={"w-full border-collapse"}>
        <tr>
          <th
            className={`text-left px-2 py-2 ${themeRootClassMap[PRICE_FRAMING_HEADER_THEME_MAP[priceFramingExperimentGroup]]}`}
            data-theme={PRICE_FRAMING_HEADER_THEME_MAP[priceFramingExperimentGroup]}
            scope="col"
            colSpan={3}
          >
            <div className="flex items-center justify-between">
              <Text tag="span" display="subtitle" size={legacySizeCollectionMap.bodyLg}>{ title }</Text>
            </div>
          </th>
        </tr>
        <>
          { state === DisplayState.READY ? (
            <>

              <>
                { items?.map(((item, index) => (
                  <SummaryDetailedRow
                    key={index}
                    item={item}
                    className={(items.length % 2 === 0 && !children) ? "odd:bg-light-grey even:bg-white" : "even:bg-light-grey odd:bg-white"}
                    reloadSummary={reloadSummary}
                    flowSlug={flowSlug}
                    priceFramingExperimentGroup={priceFramingExperimentGroup}
                  />
                ))) }
              </>
              { children && (
                <tr className={`h-auto ${items.length % 2 === 0 ? "bg-light-grey" : "bg-white"} `}>
                  <td className="text-left px-2 pb-2" colSpan={3}>
                    { children }
                  </td>
                </tr>
              ) }
              <>
                { footer?.map(((foot, index) => (
                  <>
                    <tr key={index} className={`${index === footer.length - 1 ? themeRootClassMap[PRICE_FRAMING_FOOTER_THEME_MAP[priceFramingExperimentGroup]] : (items.length % 2 === 0 || priceFramingExperimentGroup !== "test") ? themeRootClassMap.light : themeRootClassMap["light-grey"]}`}>
                      <th colSpan={2} scope="row" className={`align-top ${footerCellStyle} ${leftCellStyle}`}>
                        <Text tag="span" display={`${foot?.title?.bold ? "subtitle" : "default"}`} size={(foot.type && [ "savings", "ongoing" ].includes(foot.type)) ? legacySizeCollectionMap.titleXs : legacySizeCollectionMap.titleSm} color={foot.type === "savings" ? "success" : "default"}>{ foot?.title?.value }</Text>
                        <Text tag="span" display={`${foot?.titleExtraDetail?.bold ? "subtitle" : "default"}`} size={legacySizeCollectionMap.bodySm}>{ foot?.titleExtraDetail?.value }</Text>
                      </th>
                      <td colSpan={1} className={rightCellStyle}>
                        <div className="flex flex-row justify-end items-center">
                          <Text tag="span" className={`text-right pr-3 ${foot?.preBody?.strike ? "line-through" : ""}`} display={`${foot?.preBody?.bold ? "subtitle" : "default"}`} size={{ default: "3xs", md: "2xs" }}>{ foot?.preBody?.value }</Text>
                          <Text
                            tag="span"
                            className={`text-right ${foot?.body?.strike ? "line-through" : ""}`}
                            display={`${foot?.body?.bold ? "subtitle" : "default"}`}
                            size={
                              ((foot.type && [ "savings", "ongoing" ].includes(foot.type)) ||
                              priceFramingExperimentGroup === "test") ?
                                legacySizeCollectionMap.titleXs : legacySizeCollectionMap.titleSm
                            }
                            color={(foot.type === "savings" && priceFramingExperimentGroup === "test") ? "success" : "default"}
                          >{ foot?.body?.value }</Text>
                        </div>
                        <Text className={`text-right pt-2 ${foot?.bodyExtra?.strike ? "line-through" : ""}`} display={`${foot?.bodyExtra?.bold ? "subtitle" : "default"}`} size={legacySizeCollectionMap.titleXs}>
                          { foot?.bodyExtra?.value }
                        </Text>
                      </td>

                    </tr>
                  </>
                ))) }
              </>

            </>
          ) : (
            <tr>
              <td colSpan={2} className="flex justify-center items-center py-8">
                <LoadingSpinner color="black" size={32}/>
              </td>
            </tr>
          ) }
        </>
      </Table>
    </div>
  );
};

interface ISummaryDetailedRow {
  item: ISummaryItem,
  className: string,
  reloadSummary: () => void,
  flowSlug: string,
  priceFramingExperimentGroup: TPriceFramingGroup;
}

const SummaryDetailedRow: FC<ISummaryDetailedRow> = ({
  item,
  className,
  reloadSummary,
  flowSlug,
  priceFramingExperimentGroup,
}) => {
  const { setFlowFieldValue } = useNewFormsServiceStore();
  const [ isExpanded, setIsExpanded ] = useState<boolean>(false);
  const setCatPlanRemoved = useCallback((catId: string | undefined, state: boolean) => {
    if (!catId) {
      console.error("Tried to remove item with no catId", { item });
      return;
    }
    setFlowFieldValue(flowSlug, "catPlanRemoved", {
      submitValue: state,
      displayValue: state,
    }, catId);
    reloadSummary();
  }, [ reloadSummary, setFlowFieldValue, flowSlug ]);

  return (
    <tr
      className={`h-auto ${className}`}>
      <th scope="row" colSpan={2} className={leftCellStyle}>
        <div className="flex justify-start items-center w-full">
          <Text className={`${item?.title?.strike ? "line-through" : ""}`} tag="span" size={legacySizeCollectionMap.bodySm}>
            { item?.title?.value }
          </Text>
          { item?.titleExtraDetail && (
            <IconButton icon={isExpanded ? "arrowUp" : "arrowDown"} label="Toggle extra detail" size="2xsmall"
              onClick={() => setIsExpanded(!isExpanded)}/>
          ) }
        </div>
        { isExpanded && item?.titleExtraDetail && (
          <Text className={`${item.titleExtraDetail.strike ? "line-through" : ""}`}
            size={{ default: "3xs", md: "2xs" }}>{ item?.titleExtraDetail.value }</Text>
        ) }
        { item?.removalData && (item.removalData.planRemoved ? (
          <button onClick={() => setCatPlanRemoved(item?.removalData?.linkingId, false)}
            className="text-3xs text-green disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.addTitle }</button>
        ) : <button onClick={() => setCatPlanRemoved(item?.removalData?.linkingId, true)}
          className="text-3xs text-red disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.removeTitle }</button>)
        }
      </th>
      <td colSpan={1} className={rightCellStyle}>
        <div className="flex flex-row justify-end items-center">
          <Text tag="span" className={`text-right pr-3 ${item?.preBody?.strike ? "line-through" : ""}`} display={`${item?.preBody?.bold ? "subtitle" : "default"}`} size={{ default: "3xs", md: "2xs" }}>{ item?.preBody?.value }</Text>
          <Text
            tag="span"
            className={`text-right ${item?.body?.strike ? "line-through" : ""}`}
            size={legacySizeCollectionMap.bodySm}
            display={`${item?.body?.bold ? item?.body?.uppercase ? "title" : "subtitle" : "default"}`}
            color={item.body?.highlight ? "success" : "default"}
          >{ item?.body?.value }</Text>
        </div>
        <Text className={`text-right ${item?.bodyExtra?.strike ? "line-through" : ""}`}
          display={`${(item?.bodyExtra?.bold && priceFramingExperimentGroup !== "test") ? "subtitle" : "default"}`} size={priceFramingExperimentGroup === "test" ? legacySizeCollectionMap.bodySm : legacySizeCollectionMap.titleSm}>
          { item?.bodyExtra?.value }
        </Text>
      </td>
    </tr>
  );
};

export default SummaryDetailed;
