import { FC, PropsWithChildren } from "react";

import TaggedSummaryGroup, {
  ITaggedSummaryGroupProps,
} from "./TaggedSummaryGroup";

export interface ITaggedSummaryProps {
  groups: ITaggedSummaryGroupProps[];
}

const TaggedSummary: FC<PropsWithChildren<ITaggedSummaryProps>> = ({
  groups,
  children,
}) => (
  <div
    className="bg-brand p-4"
    data-component={TaggedSummary.name}
  >
    { groups.map((group) => <TaggedSummaryGroup key={group.title} {...group} />) }
    <div data-children>
      { children }
    </div>
  </div>
);

export default TaggedSummary;
