import { FC, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";

import { PublicConfig } from "apps/website/config/public";
import {
  getFlowFieldValuesForAction,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { useAPI } from "apps/website/hooks/useAPI";

import {
  getActionTrackingInfo,
} from "../../../utils/tracking/getActionTrackingInfo";

import CardDetailsForm from "./CardDetailsForm/CardDetailsForm";

const stripePromise = loadStripe(PublicConfig.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);
const fonts = [
  {
    family: "GreedBold",
    src: "url(/fonts/greed-bold.woff2)",
    display: "block",
  },
];

export interface ICardDetails {
  isDisabled: boolean;
  onSubmit(setupIntent: string): void;
  onCancel(): void;
  flowSlug: string;
}

const CardDetails: FC<ICardDetails> = ({ onSubmit, isDisabled, onCancel, flowSlug }) => {

  const formsActionsAPI = useAPI().FormActions;
  const posthog = usePostHog();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const [ clientSecretForCardDetails, setClientSecretForCardDetails ] = useState<string>();
  const [ shouldBeDisabled, setShouldBeDisabled ] = useState<boolean>(isDisabled);

  useEffect(() => {
    const setupClientSecretForCardDetails = async () => {
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowSlug);
      const result = await formsActionsAPI.performAction(
        "setupStripeIntent",
        fieldSubmitValuesMap,
        "UNKNOWN",
        flowSlug,
        undefined,
        featureFlags,
        getActionTrackingInfo(cookies),
      );
      if (result.success) {
        setClientSecretForCardDetails(result.responseBody as string);
      } else {
        setShouldBeDisabled(true);
      }
    };
    void setupClientSecretForCardDetails();
  }, [ flowSlug, formsActionsAPI ]);

  return (
    <div data-component={CardDetails.name}>
      <Elements stripe={stripePromise} options={{ fonts }}>
        <CardDetailsForm
          onSubmit={onSubmit}
          isDisabled={shouldBeDisabled}
          onCancel={onCancel}
          setupIntentClientSecret={clientSecretForCardDetails ?? ""}
        />
      </Elements>
    </div>
  );
};

export default CardDetails;
