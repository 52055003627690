import {
  FC,
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { usePostHog } from "posthog-js/react";
import { usePathname, useSearchParams, useRouter } from "next/navigation";
import { useCookies } from "react-cookie";

import Input from "apps/website/components/form/Input/Input";
import Form from "apps/website/components/form/Form/Form";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Button from "apps/website/components/base/Button/Button";
import { DisplayState } from "@/constants/state";
import IconButton from "apps/website/components/base/IconButton/IconButton";
import Text from "apps/website/components/base/Text/Text";
import { useCustomerStore, useDiscountStore } from "@./state";
import { LoadingSpinner } from "@/admin-components";
import {
  getFieldValue,
  getFlowFieldValuesForAction,
  getFlowProduct,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { useAPI } from "apps/website/hooks/useAPI";
import { IPlanSummaryResponse } from "@forms/schema";
import {
  loadMentionMeRefereefind,
} from "apps/website/utils/mentionme/loadMentionMe";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

import {
  getActionTrackingInfo,
} from "../../../../utils/tracking/getActionTrackingInfo";

interface ISummaryDiscountCode {
  defaultDiscountCode?: string;
  flowId: string;
  action: string;
  allowReferralCode?: boolean;
  queryParam?: string;
}

export const SummaryDiscountCode: FC<ISummaryDiscountCode> = ({
  defaultDiscountCode,
  flowId,
  action,
  allowReferralCode,
  queryParam,
}) => {

  const formsActionsAPI = useAPI().FormActions;

  const router = useRouter();
  const query = useSearchParams();
  const pathname = usePathname();
  const { chainQueryParamsToUrl } = useQueryParams();

  const posthog = usePostHog();

  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const {
    discountCodes: discountCodesInStore,
    setDiscountCode: setDiscountCodeInStore,
  } = useDiscountStore();
  const [ displayDiscountCode, setDisplayDiscountCode ] = useState<string | undefined>(undefined);
  const [ discountCodeState, setDiscountCodeState ] = useState<DisplayState>(
    DisplayState.PROCESSING,
  );
  const [ localDiscountCode, setLocalDiscountCode ] = useState<string | undefined>(undefined);
  const { customerIds } = useCustomerStore();
  const [ showDiscount, setShowDiscount ] = useState<boolean>(true);

  useEffect(() => {
    if (window.MentionMeFiredTags) {
      const situation = "checkout";
      const implementation = "link";
      if (window.MentionMeFiredTags[implementation + situation]) {
        delete window.MentionMeFiredTags[implementation + situation];
      }
    }
  }, [ ]);

  useEffect(() => {
    async function setupDiscounts() {
      if (getFieldValue(flowId, "flowTrialType", "0") && !!getFieldValue(flowId, "flowTrialType", "0")?.data.submitValue) {
        setDiscountCodeState(DisplayState.READY);
        if (discountCodesInStore.find((dc) => dc.flowId === flowId)?.discountCode) {
          setDiscountCodeInStore(flowId, undefined);
        }
        setDisplayDiscountCode(undefined);
        setLocalDiscountCode(undefined);
        setShowDiscount(false);
        return;
      }
      setShowDiscount(true);
      if (queryParam && query.get(queryParam)) {
        console.log("setting discountCode from query param");
        const queryParamValue = query.get(queryParam) as string;
        const newQueryParams: Record<string, string> = { };
        for (const [ key, value ] of query.entries()) {
          if (key !== queryParam) {
            newQueryParams[key] = value;
          }
        }
        void router.replace(chainQueryParamsToUrl(pathname, newQueryParams));
        setDiscountCodeInStore(flowId, queryParamValue);
        setDiscountCodeState(DisplayState.READY);
        return;
      }
      if (!discountCodesInStore.find((dc) => dc.flowId === flowId)) {
        setDiscountCodeInStore(flowId, defaultDiscountCode);
      } else {
        console.log("Already have a discount with that flow id", flowId);
      }
      setDiscountCodeState(DisplayState.READY);
    }
    void setupDiscounts();
  }, [ ]);

  useEffect(() => {
    if (discountCodesInStore.find((dc) => dc.flowId === flowId)?.discountCode !== localDiscountCode) {
      setLocalDiscountCode(discountCodesInStore.find((dc) => dc.flowId === flowId)?.discountCode);
    }
  }, [ discountCodesInStore, flowId ]);

  const submitMMCode = useCallback(
    () => {
      setDiscountCodeState(DisplayState.PROCESSING);
      async function inner() {
        const featureFlags = posthog.featureFlags.getFlagVariants();
        const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowId);
        if (localDiscountCode) {
          fieldSubmitValuesMap.set("discountCode", { value: localDiscountCode });
        }
        await formsActionsAPI
          .performAction(
            action,
            fieldSubmitValuesMap,
            getFlowProduct(flowId),
            flowId,
            undefined,
            featureFlags,
            getActionTrackingInfo(cookies),
          )
          .then((response) => {
            if (!response.success) {
              setDiscountCodeState(DisplayState.ERROR);
            } else {
              setDiscountCodeInStore(flowId, localDiscountCode);
              setDisplayDiscountCode((response.responseBody as IPlanSummaryResponse).discount?.body?.value);
              setDiscountCodeState(DisplayState.READY);
            }
          })
          .catch(() => setDiscountCodeState(DisplayState.ERROR));
      }
      void inner();
    },
    [ formsActionsAPI, action, flowId, localDiscountCode, setDiscountCodeInStore ],
  );

  const onSubmitDiscountCode: FormEventHandler = useCallback(
    async (event): Promise<void> => {
      event.preventDefault();
      setDiscountCodeState(DisplayState.PROCESSING);
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowId);
      if (localDiscountCode) {
        fieldSubmitValuesMap.set("discountCode", { value: localDiscountCode });
      }
      await formsActionsAPI
        .performAction(
          action,
          fieldSubmitValuesMap,
          getFlowProduct(flowId),
          flowId,
          undefined,
          undefined,
          getActionTrackingInfo(cookies),
        )
        .then((response) => {
          if (!response.success) {
            setDiscountCodeState(DisplayState.ERROR);
          } else {
            setDiscountCodeInStore(flowId, localDiscountCode);
            setDisplayDiscountCode((response.responseBody as IPlanSummaryResponse).discount?.body?.value);
            setDiscountCodeState(DisplayState.READY);
          }
        })
        .catch(() => setDiscountCodeState(DisplayState.ERROR));
    },
    [ formsActionsAPI, action, flowId, localDiscountCode, setDiscountCodeInStore ],
  );

  useEffect(() => {
    const firstName = getFieldValue(flowId, "firstName", "0")?.data?.submitValue?.toString() || "";
    const surname = getFieldValue(flowId, "lastName", "0")?.data?.submitValue?.toString() || "";
    const email = getFieldValue(flowId, "emailAddress", "0")?.data?.submitValue?.toString() || "";
    const result = loadMentionMeRefereefind({
      fullname: `${firstName} ${surname}`,
      firstname: firstName,
      surname,
      email,
      customer_id: customerIds.find((dc) => dc.flowId === flowId)?.customerId || "",
    });
    result();
  }, [ customerIds, flowId ]);

  return (
    <div>
      { showDiscount &&
      <>
        { discountCodeState === DisplayState.READY && (
          <>
            <Grid>
              { discountCodesInStore.find((dc) => dc.flowId === flowId
                && dc.discountCode && dc.discountCode !== defaultDiscountCode) ? (
                  <Column justify="between" align="center" direction="row">
                    <IconButton
                      label="Discount code"
                      display="title"
                      icon="cross"
                      size="small"
                      onClick={() => setDiscountCodeState(DisplayState.EDITING)}
                      className="width-fit"
                    >
                      { discountCodesInStore.find((dc) => dc.flowId === flowId)?.discountCode }
                    </IconButton>
                    <Text tag="span" display="title">
                      { displayDiscountCode }
                    </Text>
                  </Column>
                ) : (
                  <Column direction="row" className="pt-2">

                    <Text size={legacySizeCollectionMap.bodySm} tag="div">
                      <button
                        onClick={() => setDiscountCodeState(DisplayState.EDITING)}
                        className="cursor-pointer"
                      >
                    Got a <u>discount code</u>
                      </button>
                    </Text>
                    { allowReferralCode && (
                      <Text tag="div" size={legacySizeCollectionMap.bodySm} className="inline" onClick={() => setDiscountCodeState(DisplayState.EDITING)}>

                    &nbsp;or&nbsp;
                        <div id="mmWrapper" className="underline inline"></div>?
                      </Text>
                    ) }
                  </Column>
                ) }
              <Column direction="row" className="hidden">
                <input
                  type="submit"
                  id="coupon-button"
                  onClick={() => submitMMCode()}
                  value="Apply"
                />
                <input id="coupon-value" type="text" onChange={(event) => setLocalDiscountCode(event.target.value)} />
              </Column>
            </Grid>
          </>
        ) }
        { discountCodeState === DisplayState.EDITING && (
          <Form action="" onSubmit={onSubmitDiscountCode}>
            <Grid>
              <Column>
                <button
                  onClick={() => setDiscountCodeState(DisplayState.READY)}
                  className="text-left"
                >
                  <u>Close</u>
                </button>
              </Column>
              <Column direction={{ lg: "row" }}>
                <Input
                  label="Discount code"
                  hideLabel
                  placeholder="Enter your code"
                  type="text"
                  value={localDiscountCode}
                  name="discount_code"
                  onChange={(event) => setLocalDiscountCode(event.target.value)}
                  className="flex-1 mr-4"
                />
                <Button
                  type="submit"
                  disabled={
                    !localDiscountCode?.trim().length ||
                  localDiscountCode === discountCodesInStore.find((dc) => dc.flowId === flowId)?.discountCode
                  }
                  size="fit-content"
                >
                Apply
                </Button>
              </Column>
              <>
                { discountCodesInStore.find((dc) => dc.flowId === flowId) && (
                  <Column className="border-solid border-2 border-orange box-border p-4">
                    <Text tag="span" align="center">
                  This discount code will replace any existing ones.
                    </Text>
                  </Column>
                ) }
              </>
            </Grid>
          </Form>
        ) }
        { discountCodeState === DisplayState.PROCESSING && (
          <Grid>
            <Column>
              <div className="w-fit">
                <LoadingSpinner color="black" size={32} />
              </div>
            </Column>
          </Grid>
        ) }
        { discountCodeState === DisplayState.ERROR && (
          <Grid>
            <Column>
              <div className="text-orange">
                <Text tag="span" color="inherit">
              Sorry, this code is not valid.
                </Text>
              </div>
              <button
                onClick={() => setDiscountCodeState(DisplayState.EDITING)}
                className="text-left"
              >
                <u>Try again</u>
              </button>
            </Column>
          </Grid>
        ) }
      </>
      }
    </div>
  );
};
