import { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import StepCounterSelect from
  "apps/website/components/form/StepCounterSelect/StepCounterSelect";
import { StepCounterSelectField } from "libs/forms-schema/src";
import {
  FieldData,
  getFlowFieldValuesForAction,
  getFlowFieldValuesForInterpolation,
  getFlowProduct,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { getStoryBlokTooltip } from "apps/website/utils/storyblok/forms";
import { useAPI } from "apps/website/hooks/useAPI";

import {
  getActionTrackingInfo,
} from "../../../utils/tracking/getActionTrackingInfo";

import RecommendedAlertBox from "./extentions/RecommendedAlertBox";

export interface IFormServiceStepCounterSelect {
  field: StepCounterSelectField;
  selected?: FieldData;
  optional?: boolean;
  onChange(value: FieldData): void;
  flowId: string;
  linkingId: string;
}

export const FormServiceStepCounterSelect: FC<IFormServiceStepCounterSelect> = ({
  field,
  onChange,
  optional,
  selected,
  flowId,
  linkingId,
}) => {
  const formsActionsAPI = useAPI().FormActions;

  const [ defaultSelected, setDefaultSelected ] = useState<string | undefined>(undefined);

  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  useEffect(() => {
    void formsActionsAPI
      .performAction(
        field.default_selected,
        getFlowFieldValuesForAction(flowId),
        getFlowProduct(flowId),
        flowId,
        undefined,
        undefined,
        getActionTrackingInfo(cookies),
      )
      .then((data) => setDefaultSelected(data.responseBody?.toString()))
      .catch((err) => console.log(err));
  }, [ ]);

  useEffect(() => {
    if (!selected && defaultSelected) {
      const newSelectedOption = field.options.map((option) => ({
        optValue: option.value,
        title: option.title,
      })).find((s) => s.optValue === defaultSelected);
      onChange({
        submitValue: newSelectedOption?.optValue,
        displayValue: newSelectedOption?.title,
      });
    }
  }, [ defaultSelected ]);

  const handleSelect = (selectedValue: string) => {
    const actualSelectedOption = field.options.map((option) => ({
      isSelected: option.selected,
      optValue: option.value,
      title: option.title,
    })).find((s) => s.optValue as string === selectedValue);
    onChange({
      submitValue: actualSelectedOption?.optValue,
      displayValue: actualSelectedOption?.title,
    });
  };

  const findSelectedOption = () => {
    const newSelectedOption = field.options.map((option) => ({
      isSelected: option.selected,
      optValue: option.value,
      title: option.title,
    })).find((s) => s.isSelected);
    return newSelectedOption?.optValue;
  };

  return (
    <>
      <StepCounterSelect
        labelStyle={ field.label_position === "center" ? "title" : "alternative" }
        name={field.name}
        label={field.label ?? ""}
        options={field.options.map((option) => ({
          value: option.value,
          name: option.title,
          selected: option.selected,
          advancedOptions: option.advanced_options,
        }))}
        optional={optional}
        selected={
          selected?.submitValue as string ||
            findSelectedOption() as string
             || undefined}
        hideLabel={field.hide_label}
        labelPosition={field.label_position}
        onChange={(value) => handleSelect(value)}
        tooltip={field.tooltip ? getStoryBlokTooltip(
          field.tooltip,
          getFlowFieldValuesForInterpolation(flowId, linkingId, field.tooltip?.[0]?.interpolation_behavior),
        ) : undefined}
      />
      <RecommendedAlertBox
        defaultSelected={defaultSelected ?? ""}
        selected={selected?.submitValue as string}
        field={field}
      />
    </>
  );
};
