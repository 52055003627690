import { FC, memo } from "react";

import ListItem from "../../base/ListItem/ListItem";
import Tag from "../../base/Tag/Tag";
import Text from "../../base/Text/Text";
import { AvailableTag } from "../../base/Text/Text.map";

export interface ITaggedSummaryGroupProps {
  title: string;
  titleTag?: AvailableTag;
  tags: string[];
}

const TaggedSummaryGroup: FC<ITaggedSummaryGroupProps> = ({
  title,
  titleTag = "h3",
  tags,
}) => (
  <div
    className="my-1"
    data-component={TaggedSummaryGroup.name}
  >
    <Text
      display="default"
      tag={titleTag}
      size={{
        default: "2xs",
        md: "default",
      }}
      className="first-letter:capitalize"
    >
      { title }
    </Text>
    <ol
      className="flex flex-row flex-wrap gap-2 my-2"
    >
      { tags.filter((filteredTag) => filteredTag.trim() !== "").map((tag, index) => (
        <ListItem
          key={`${index}-${tag}`}
        >
          <Tag
            size={"small"}
            theme={"dark"}
          >
            { tag }
          </Tag>
        </ListItem>
      )) }
    </ol>
  </div>
);

export default memo(TaggedSummaryGroup);
