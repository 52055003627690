"use client";

import clsx from "clsx";
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useCallback,
} from "react";

interface Props extends PropsWithChildren {
  value?: string;
  onChange(s: string): void;
  placeholder?: string;
}

export const Select: FC<Props> = ({
  value,
  children,
  onChange,
  placeholder = "",
}) => (
  <select
    value={value ?? placeholder}
    onChange={useCallback((e: ChangeEvent<HTMLSelectElement>) => onChange(e.target.value), [ onChange ])}
    className={clsx(
      "rounded-md border-gray-400 text-right",
      value === undefined && "text-gray-400",
    ) }
    placeholder="Choose"
  >
    <option disabled hidden>{ placeholder }</option>
    { children }
  </select>
);
