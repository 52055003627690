import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  memo,
} from "react";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";

import { IImageProps } from "apps/website/components/base/Image/Image";
import {
  useNewFormsServiceStore,
  getFlowFieldValuesForAction,
  getFlowProduct,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  ActionCTAField,
  Recipe,
  RecipesForCatResponse,
  SubmitFieldData,
} from "@forms/schema";
import QuantitySelectionCard from "apps/website/components/feature/QuantitySelectionCard/QuantitySelectionCard";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import Column from "apps/website/components/layout/Column/Column";
import {
  AlertPrompt,
} from "apps/website/components/feature/AlertPrompt/AlertPrompt";
import Text from "apps/website/components/base/Text/Text";
import Accordion from "apps/website/components/feature/Accordion/Accordion";
import Grid from "apps/website/components/layout/Grid/Grid";
import { useAPI } from "apps/website/hooks/useAPI";
import {
  interpolateString,
  Interpolator,
} from "libs/form-utils/src/lib/interpolate";
import { WithTestID } from "apps/website/types";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import {
  getActionTrackingInfo,
} from "../../../../utils/tracking/getActionTrackingInfo";
import { FormServiceCTA } from "../CTA";

interface IRecipeItem {
  defaultQuantity: number;
  image?: IImageProps;
  description: string;
  name: string;
  internalName: string;
}

interface IFormServiceRecipes extends WithTestID {
  availableRecipes: IRecipeItem[];
  totalQuantity: number;
  flowSlug: string;
  action: string;
  linkingId: string;
  seeMoreText: string;
  seeLessText: string;
  hideSuccessMessage?: boolean;
  selectedRecipesTheme?: Theme;
  cta?: ActionCTAField;
  next?: (forceNext: boolean | undefined) => void;
}

interface IFormServiceRecipesAccordion {
  correctNumberOfRecipes: boolean;
  numberOfRecipesToProceed: number;
  flowSlug: string;
  linkingId: string;
  totalQuantity: number;
  selectedRecipes: Map<Recipe, RecipeCardDetails>;
  numberOfSelectedRecipes?: number;
  allergicRecipes: Set<AllergicRecipeCardDetails>;
  ignoreRecipes: Set<Recipe>;
  updateSelectedRecipesMap(r: Recipe, add: boolean): void;
}

type RecipeCardDetails = AllergicRecipeCardDetails & { quantity: number; recommended: boolean };
type AllergicRecipeCardDetails = Omit<IRecipeItem, "defaultQuantity">;

const AccordionContent: FC<IFormServiceRecipesAccordion> = memo(({
  correctNumberOfRecipes,
  numberOfRecipesToProceed,
  flowSlug,
  linkingId,
  totalQuantity,
  selectedRecipes,
  numberOfSelectedRecipes,
  allergicRecipes,
  ignoreRecipes,
  updateSelectedRecipesMap,
}) => {

  const posthog = usePostHog();

  return (
    <Grid>
      <Column>
        <Text display="subtitle" size={legacySizeCollectionMap.titleMd} align="center">
          { correctNumberOfRecipes ?
            (<>{ interpolateString("[[$catName]]", getFlowFieldValuesForInterpolation(flowSlug, linkingId, "GET_RELATED"), Interpolator.FRONTEND) }&apos;s { totalQuantity } units</>) :
            (<>Choose { numberOfRecipesToProceed } more unit{ numberOfRecipesToProceed > 1 ? <>s</> : "" } to proceed</>)
          }
        </Text>
      </Column>
      <Column>
        <AlertPrompt style="idea" title="Pro tip" size="fill">
          <Text align="center">
          These are our most popular recipes – and what we recommend { interpolateString("[[$catName]]", getFlowFieldValuesForInterpolation(flowSlug, linkingId, "GET_RELATED"), Interpolator.FRONTEND) } sinks { interpolateString("[[$catPronounHisHerTheir]]", getFlowFieldValuesForInterpolation(flowSlug, linkingId, "GET_RELATED"), Interpolator.FRONTEND) } teeth into first.
          </Text>
        </AlertPrompt>
      </Column>
      <Column>
        <Grid tag="ul">
          <>
            { [ ...selectedRecipes.keys() ].filter((r) => selectedRecipes.get(r)?.recommended ?? false).map(
              (recipe) => (
                <QuantitySelectionCard
                  key={recipe as string}
                  value={selectedRecipes.get(recipe)?.quantity ?? 0}
                  name={recipe as string}
                  description={renderStoryBlokRichText(selectedRecipes.get(recipe)?.description ?? "")}
                  image={selectedRecipes.get(recipe)?.image}
                  plusEnabled={(numberOfSelectedRecipes ?? 0) < totalQuantity}
                  minusEnabled={(selectedRecipes.get(recipe)?.quantity ?? 0) > 0}
                  onClickPlus={() => updateSelectedRecipesMap(recipe, true)}
                  onClickMinus={() => updateSelectedRecipesMap(recipe, false)}
                  recommended={selectedRecipes.get(recipe)?.recommended ?? false}
                  tagText={(selectedRecipes.get(recipe)?.recommended ?? false) ? "recommended" : ""}
                ></QuantitySelectionCard>
              ),
            ) }
            { (posthog.getFeatureFlag("hide-non-default-recipes") === "test" && flowSlug !== "fresh-new-user-dtc") ? (
              <></>
            ) : (
              <>
                { [ ...selectedRecipes.keys() ].filter((r) => !selectedRecipes.get(r)?.recommended).map((recipe) => (
                  <QuantitySelectionCard
                    key={recipe as string}
                    value={selectedRecipes.get(recipe)?.quantity ?? 0}
                    name={recipe as string}
                    description={renderStoryBlokRichText(selectedRecipes.get(recipe)?.description ?? "")}
                    image={selectedRecipes.get(recipe)?.image}
                    plusEnabled={(numberOfSelectedRecipes ?? 0) < totalQuantity}
                    minusEnabled={(selectedRecipes.get(recipe)?.quantity ?? 0) > 0}
                    onClickPlus={() => updateSelectedRecipesMap(recipe, true)}
                    onClickMinus={() => updateSelectedRecipesMap(recipe, false)}
                    recommended={false}
                    tagText={(selectedRecipes.get(recipe)?.recommended ?? false) ? "recommended" : ""}
                  ></QuantitySelectionCard>
                )) }
                { [ ...allergicRecipes ].filter(
                  (mappedRecipe) => !ignoreRecipes.has(mappedRecipe.internalName as Recipe),
                ).map((recipe) => (
                  <Column key={recipe.internalName as string}>
                    <QuantitySelectionCard
                      name={recipe.name}
                      description={renderStoryBlokRichText(recipe.description)}
                      image={recipe.image}
                      allergic={true}
                      allergicText={`${interpolateString("[[$catPronounHesShesTheyre]]", getFlowFieldValuesForInterpolation(flowSlug, linkingId, "GET_RELATED"), Interpolator.FRONTEND)} allergic to this recipe`}
                      recommended={false}
                    />
                  </Column>
                )) }
              </>
            ) }
          </>
        </Grid>
      </Column>
    </Grid>
  );
});

AccordionContent.displayName = "AccordionContent";

export const FormServiceRecipesHighlightingRecipesTest: FC<IFormServiceRecipes> = memo(({
  "data-testid": testId,
  cta,
  availableRecipes,
  totalQuantity,
  flowSlug,
  action,
  linkingId,
  seeMoreText,
  seeLessText,
  selectedRecipesTheme,
  next,
}) => {
  const [ numberOfSelectedRecipes, setNumberOfSelectedRecipes ] = useState<number>();
  const [ selectedRecipes, setSelectedRecipes ] = useState<Map<Recipe, RecipeCardDetails>>(new Map());
  const [ allergicRecipes, setAllergicRecipes ] = useState<Set<AllergicRecipeCardDetails>>(new Set());
  const [ ignoreRecipes, setIgnoreRecipes ] = useState<Set<Recipe>>(new Set());
  const { setFlowFieldValue } = useNewFormsServiceStore();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const formsActionsAPI = useAPI().FormActions;
  const posthog = usePostHog();

  useEffect(() => {
    if (selectedRecipes && selectedRecipes.size > 0) {
      setNumberOfSelectedRecipes([ ...selectedRecipes.values() ]
        .map((r) => +r.quantity)?.reduce((prev, cur) => (prev + cur)));
    } else {
      setNumberOfSelectedRecipes(0);
    }
  }, [ selectedRecipes ]);

  const updateSelectedRecipesMap = useCallback((r: Recipe, add: boolean) => {
    const value = selectedRecipes.get(r);
    if (value) {
      if (add) {
        setSelectedRecipes(new Map(selectedRecipes.set(r, {
          ...value,
          quantity: +value.quantity + 1,
        })));
      } else {
        setSelectedRecipes(new Map(selectedRecipes.set(r, {
          ...value,
          quantity: +value.quantity - 1,
        })));
      }
    }
    const recipesMap: { [recipe: string]: number } = {};
    selectedRecipes.forEach((qty, rec) => {
      recipesMap[rec] = +qty.quantity;
    });
    setFlowFieldValue(flowSlug, "recipesWithQuantities", { submitValue: recipesMap, displayValue: recipesMap }, linkingId);
  }, [ flowSlug, linkingId, selectedRecipes, setFlowFieldValue ]);

  useEffect(() => {
    async function getDefaultRecipes() {
      setFlowFieldValue(flowSlug, "totalRecipesRequired", { submitValue: totalQuantity, displayValue: totalQuantity }, linkingId);
      const defaultFieldSubmitValuesMap = getFlowFieldValuesForAction(flowSlug);
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowSlug);
      const defaultRecipesMap: { [recipe: string]: number } = {};
      availableRecipes.forEach((recipe) => {
        defaultRecipesMap[recipe.internalName] = recipe.defaultQuantity;
      });

      const recipesMap: { [recipe: string]: number } = {};
      availableRecipes.forEach((recipe) => {
        const inStore = fieldSubmitValuesMap.get("recipesWithQuantities");
        const currentRecipes = Array.isArray(inStore) ? (fieldSubmitValuesMap.get("recipesWithQuantities") as SubmitFieldData[]).find((cr) => cr.linkingId === linkingId) : inStore;
        if (currentRecipes) {
          recipesMap[recipe.internalName] = (currentRecipes.value as {
            [recipe: string]: number })[recipe.internalName] || 0;
        } else {
          recipesMap[recipe.internalName] = recipe.defaultQuantity;
        }
      });
      defaultFieldSubmitValuesMap.set("recipesWithQuantities", [ { value: defaultRecipesMap, linkingId } ]);
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const defaultResult = await formsActionsAPI.performAction(
        action,
        defaultFieldSubmitValuesMap,
        getFlowProduct(flowSlug),
        flowSlug,
        linkingId,
        featureFlags,
        getActionTrackingInfo(cookies),
      );
      fieldSubmitValuesMap.set("recipesWithQuantities", [ { value: recipesMap, linkingId } ]);
      const result = await formsActionsAPI.performAction(
        action,
        fieldSubmitValuesMap,
        getFlowProduct(flowSlug),
        flowSlug,
        linkingId,
        featureFlags,
        getActionTrackingInfo(cookies),
      );
      const defaultResponseBody = defaultResult.responseBody as RecipesForCatResponse;
      const responseBody = result.responseBody as RecipesForCatResponse;
      if (result && result.success && responseBody && responseBody.allowedRecipesAndQuantities) {
        const recipeQuantityMap: { [recipe: string]: number } = responseBody.allowedRecipesAndQuantities;
        setSelectedRecipes(new Map());
        for (const [ rec, qty ] of Object.entries(recipeQuantityMap)) {
          const recipeItem = availableRecipes.find((r) => r.internalName === rec);
          setSelectedRecipes(new Map(selectedRecipes.set(rec as Recipe, {
            ...recipeItem,
            description: recipeItem?.description ?? "",
            name: recipeItem?.name ?? "",
            internalName: recipeItem?.internalName ?? "",
            quantity: qty,
            recommended: qty > 0,
          })));
        }
        if (responseBody.notAllowedRecipes) {
          const notAllowedRecipesArr: Recipe[] = responseBody.notAllowedRecipes;
          setIgnoreRecipes(new Set(notAllowedRecipesArr));
        }
        if (responseBody.allergicRecipes) {
          const allergicRecipesArr: Recipe[] = responseBody.allergicRecipes;
          const allergies: Set<AllergicRecipeCardDetails> = new Set();
          allergicRecipesArr.forEach((rec) => {
            const recipeItem = availableRecipes.find((r) => r.internalName === rec);
            allergies.add({
              ...recipeItem,
              description: recipeItem?.description ?? "",
              name: recipeItem?.name ?? "",
              internalName: recipeItem?.internalName ?? "",
            });
          });
          setAllergicRecipes(new Set(allergies));
        }

        if (selectedRecipes && selectedRecipes.size > 0) {
          const totalSelected = [ ...selectedRecipes.values() ]
            .map((r) => +r.quantity)?.reduce((prev, cur) => (+(prev) + +(cur)));
          setNumberOfSelectedRecipes(totalSelected);
        } else {
          setNumberOfSelectedRecipes(totalQuantity);
        }
      }
      const storeRecipeMap: { [recipe: string]: number } = {};
      selectedRecipes.forEach((qty, rec) => {
        storeRecipeMap[rec] = +qty.quantity;
      });
      const defaultRecipes = Object.entries(
        defaultResponseBody.allowedRecipesAndQuantities,
      ).filter(([ , value ]) => value > 0).map(([ key ]) => key);
      setFlowFieldValue(flowSlug, "defaultRecipes", { submitValue: defaultRecipes, displayValue: defaultRecipes }, linkingId);
      setFlowFieldValue(flowSlug, "recipesWithQuantities", { submitValue: storeRecipeMap, displayValue: storeRecipeMap }, linkingId);
    }
    void getDefaultRecipes();
  }, [ ]);

  const correctNumberOfRecipes = useMemo(
    () => {
      const result = +(numberOfSelectedRecipes ?? 0) === +totalQuantity;
      return result;
    },
    [ numberOfSelectedRecipes, totalQuantity ],
  );

  const numberOfRecipesToProceed = useMemo(
    () => +totalQuantity - +(numberOfSelectedRecipes ?? 0),
    [ numberOfSelectedRecipes, totalQuantity ],
  );

  return (
    <>
      <Column data-testid={testId} className={selectedRecipesTheme ? `${themeRootClassMap[selectedRecipesTheme]} p-4 -mt-4` : ""}>
        { cta && (
          <Grid>
            <Column align="center" justify="center">
              <FormServiceCTA field={cta} flowId={flowSlug} next={next}></FormServiceCTA>
              <Spacer size="lg" />
            </Column>
          </Grid>
        ) }
      </Column>
      <Column>
        <Accordion title={seeMoreText} openTitle={seeLessText} Content={
          <AccordionContent
            correctNumberOfRecipes={correctNumberOfRecipes}
            numberOfRecipesToProceed={numberOfRecipesToProceed}
            flowSlug={flowSlug}
            linkingId={linkingId}
            totalQuantity={totalQuantity}
            selectedRecipes={selectedRecipes}
            numberOfSelectedRecipes={numberOfSelectedRecipes}
            allergicRecipes={allergicRecipes}
            ignoreRecipes={ignoreRecipes}
            updateSelectedRecipesMap={updateSelectedRecipesMap}
          />
        } type="underline" data-testid={`${testId}-accordion`}/>
      </Column>
    </>);
});

FormServiceRecipesHighlightingRecipesTest.displayName = "FormServiceRecipesHighlightingRecipesTest";
