import { IImage } from "apps/website/components/base/Image/Image";

export const fieldsetImageMap: Record<string, IImage> = {
  catProfile: {
    image: {
      src: "/images/forms/fieldsets/cat-profile.webp",
      width: 427,
      height: 427,
    },
    alt: "Cat profile",
  },
};
