"use client";

/* ============================================================
|
| THIS IS A BASIC PROOF OF CONCEPT FOR THE FORMS SERVICE
| LIFE OUTSIDE OF STORYBLOK, HENCE THE "UNNECESSARY" EXTRA
| CODE FOR THIS PIECE OF WORK
|
| CURRENTLY WE HARDCODE THE ONLY LINKING ID AS 0
|
/ ============================================================= */

import {
  ChangeEvent,
  FC,
  useEffect,
  useState,
} from "react";
import { Dayjs } from "dayjs";
import { useCookies } from "react-cookie";
import { usePostHog } from "posthog-js/react";
import { useRouter } from "next/navigation";

import TextArea from "apps/website/components/form/TextArea/TextArea";
import Button from "apps/website/components/base/Button/Button";
import ScoreSelect from "apps/website/components/form/ScoreSelect/ScoreSelect";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import ReactionSelectList from "apps/website/components/form/ReactionSelectList/ReactionSelectList";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import { FieldName, FieldValue } from "@forms/schema";
import { useAPI } from "apps/website/hooks/useAPI";
import { SubmissionFieldValues, useNewFormsServiceStore } from "@./state";
import {
  getActionTrackingInfo,
} from "apps/website/utils/tracking/getActionTrackingInfo";
import { DisplayState } from "@/constants/state";

type TFieldName = "improvements" | "reason" | "score" | "categoryScoring";

type TCategoryScoringFieldName = "companymission" | "cost" | "customerService" | "eatsTheFood" | "healthBenefits" | "packaging" | "quality" | "subscriptionService" | "sustainability";
type TSomeOtherSetFieldName = "example" | "anotherExample";

type TChildFieldName = (TCategoryScoringFieldName) | (TSomeOtherSetFieldName);

const components = {
  textarea: TextArea,
  score: ScoreSelect,
  reactionList: ReactionSelectList,
} as const;

type TFlowKey = "cancellation";

type TFieldKey = keyof typeof components;

type TBaseFieldValue = string | number | Dayjs | string[] | number[] | Dayjs[] | undefined;
type TFieldValue = TBaseFieldValue | Partial<Record<TChildFieldName, TBaseFieldValue >>;

type TLinkingId = string | number;

type TFieldStore = Record<TLinkingId, Partial<Record<TFieldName, TFieldValue>>>;

type TConditionOperator = "equal" | "notEqual" | "in";

interface ICondition {
  name: TFieldName;
  operator: TConditionOperator;
  value: TFieldValue;
}

type TValidator = "IS_HELLO" | "IS_POSITIVE"; // <-- THIS IS AN EXAMPLE VALIDATOR NAME - EXAMPLE OF USE FURTHER DOWN

type TFieldValidators = Partial<Record<TFieldName, TValidator[]>>;
type TFieldChildValidators = Partial<Record<TFieldName, Partial<Record<TChildFieldName, TValidator[]>>>>;

interface IBaseField {
  label: string;
  value?: TFieldValue;
  conditions?: ICondition[];
  required?: boolean;
}
interface IChildField extends IBaseField {
  name: TChildFieldName;
  defaultValue?: TBaseFieldValue;
}
interface IField extends IBaseField {
  name: TFieldName;
  component: TFieldKey;
  defaultValue?: TFieldValue
  children?: IChildField[];
  optional?: boolean;
}

interface IFieldset {
  fields: IField[];
  repeatOn?: TFieldName;
}

interface ISection {
  slug: string;
  fieldsets: IFieldset[];
}

interface IForm {
  slug: string;
  sections: ISection[];
}

interface IFlow {
  forms: IForm[];
}

type TFlows = Record<TFlowKey, IFlow>;

const FIELD_VALIDATOR_MAP: TFieldValidators = {
  // reason: [ "IS_HELLO" ], <-- THIS IS AN EXAMPLE VALIDATOR
};

const FIELD_CHILD_VALIDATOR_MAP: TFieldChildValidators = {
  // categoryScoring: {
  //  companymission: [ "IS_POSITIVE" ],
  // }, // <-- Example of a child validator
};

const validatorHello = (value: TFieldValue) => value === "Hello";
const validatorIsPositive = (value: TFieldValue) => value === "POSITIVE";

const FIELD_VALIDATORS: Record<TValidator, (value: TFieldValue) => boolean> = {
  IS_HELLO: (value) => validatorHello(value),
  IS_POSITIVE: (value) => validatorIsPositive(value),
};
/* ============================================================
|
| THIS EXAMPLE SHOWS HOW CONDITIONAL LOGIC WORKS
|
/ =============================================================

const SURVEYS: TFlows = {
  cancellation: {
    forms: [
      {
        slug: "feedback",
        sections: [
          {
            slug: "feedback",
            fieldsets: [
              {
                repeatOn: "score",
                fields: [
                  {
                    component: "score",
                    name: "score",
                    label: "On a scale from 0-10, how likely are you to recommend KatKin to a fellow cat parent? ",
                    required: true,
                    defaultValue: "",
                  },
                  {
                    component: "textarea",
                    name: "reason",
                    label: "Reason",
                    required: true,
                    defaultValue: "",
                  },
                  {
                    component: "textarea",
                    name: "improvements",
                    label: "Improvements",
                    conditions: [
                      {
                        name: "reason",
                        operator: "in",
                        value: [ "r", "e" ],
                      },
                    ],
                    defaultValue: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}; */

const SURVEYS: TFlows = {
  cancellation: {
    forms: [
      {
        slug: "feedback",
        sections: [
          {
            slug: "feedback",
            fieldsets: [
              {
                fields: [
                  {
                    component: "score",
                    name: "score",
                    label: "On a scale from 0-10, how likely are you to recommend KatKin to a fellow cat parent? ",
                    required: true,
                    defaultValue: "",
                  },
                  {
                    component: "textarea",
                    name: "reason",
                    label: "What is the main reason for your score?",
                    required: false,
                    defaultValue: "",
                  },
                  {
                    component: "textarea",
                    name: "improvements",
                    label: "What could we do better?",
                    required: false,
                    defaultValue: "",
                  },
                  {
                    component: "reactionList",
                    name: "categoryScoring",
                    label: "How would you rate us in the following?",
                    required: false,
                    defaultValue: "",
                    children: [
                      {
                        name: "quality",
                        label: "Quality of the food",
                        required: false,
                        defaultValue: "",
                      },
                      {
                        name: "healthBenefits",
                        label: "Health benefits for my cat",
                        required: false,
                        defaultValue: "",
                      },
                      {
                        name: "companymission",
                        label: "Company mission",
                        required: false,
                        defaultValue: "",
                      },
                      {
                        name: "customerService",
                        label: "Customer service",
                        required: false,
                        defaultValue: "",
                      },
                      {
                        name: "cost",
                        label: "Value for money",
                        required: false,
                        defaultValue: "",
                      },
                      {
                        name: "packaging",
                        label: "Packaging",
                        required: false,
                        defaultValue: "",
                      },
                      {
                        name: "subscriptionService",
                        label: "Subscription offerings + flexibility",
                        required: false,
                        defaultValue: "",
                      },
                      {
                        name: "sustainability",
                        label: "Sustainability",
                        required: false,
                        defaultValue: "",
                      },
                      {
                        name: "eatsTheFood",
                        label: "Cat loves the food",
                        required: false,
                        defaultValue: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

interface IFormServiceSurveyProps {
  survey: TFlowKey;
  flowSlug: string;
}

interface IComponentProps {
  field: IField;
  value: TFieldValue;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const Component: FC<IComponentProps> = ({ field, onChange, value }) => {
  const Field = components[field.component as TFieldKey];

  return <Field
    {...field}
    // @ts-ignore
    value={value}
    onChange={onChange}
  />;
};

export const FormServiceSurvey: FC<IFormServiceSurveyProps> = ({ survey = "cancellation", flowSlug }) => {

  const [ formStore, setFormStore ] = useState<TFieldStore>({}); // Should be in store when
  const formsActionsAPI = useAPI().FormActions;
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);
  const posthog = usePostHog();
  const { getActiveFieldValue } = useNewFormsServiceStore();
  const [ buttonState, setButtonState ] = useState<DisplayState>(DisplayState.READY);
  const router = useRouter();

  const updateFormStoreValue = (
    linkingId: TLinkingId,
    fieldName: TFieldName,
    fieldValue: TFieldValue,
    changeField: TFieldName | TChildFieldName,
  ) => {
    if (fieldName === changeField) {
      setFormStore({
        ...formStore,
        [linkingId]: {
          ...formStore[linkingId],
          [fieldName]: fieldValue,
        },
      });
    } else { // Is a child field
      setFormStore({
        ...formStore,
        [linkingId]: {
          ...formStore[linkingId],
          [fieldName]: {
            ...formStore[linkingId][fieldName] as Partial<Record<TFieldName, TFieldValue>>,
            [changeField]: fieldValue,
          },
        },
      });
    }
  };

  useEffect(() => {
    const createStore: TFieldStore = {
      0: {},
    };
    SURVEYS[survey].forms.forEach((form) => (
      form.sections.forEach((section) => (
        section.fieldsets.forEach((fieldset) => (
          fieldset.fields.forEach((field) => {
            if (field.children?.length) {
              const childStore: Partial<Record<TChildFieldName, TBaseFieldValue>> = {};
              field.children.forEach((child) => {
                childStore[child.name] = child.defaultValue;
              });
              createStore[0][field.name] = childStore;
            } else {
              createStore[0][field.name] = field.defaultValue;
            }
          })
        ))
      ))
    ));
    setFormStore(createStore);
  }, [ survey ]);

  const showField = (field: IField | IChildField) => {
    const { conditions } = field;
    if (!conditions || !conditions.length) return true;
    for (let index = 0; index < conditions.length; index) {
      if (conditions[index].operator === "equal") {
        return formStore[0]?.[conditions[index].name] === conditions[index].value;
      }
      if (conditions[index].operator === "notEqual") {
        return formStore[0]?.[conditions[index].name] !== conditions[index].value;
      }
      if (conditions[index].operator === "in") {
        if (Array.isArray(conditions[index].value)) {
          return (conditions[index].value as TFieldValue[]).includes(formStore[0]?.[conditions[index].name]);
        }
      }
    }
    return false;
  };

  const getSectionFields = (section: ISection) => section.fieldsets.map((fieldset) => fieldset.fields).flat();

  const baseFieldHasValue = (fieldValue: TBaseFieldValue) => {
    if (
      !fieldValue ||
      (typeof fieldValue === "string" && !fieldValue.length) ||
      (Array.isArray(fieldValue) && !fieldValue.length)
    ) {
      return false;
    }

    return true;
  };

  const fieldHasValue = (field: IField) => {
    const fieldValue = formStore[0]?.[field.name];
    return baseFieldHasValue(fieldValue as TBaseFieldValue);
  };

  const childFieldHasValue = (field: IField, childField: IChildField) => {
    const fieldValue = (
      formStore[0]?.[field.name] as Partial<Record<TChildFieldName, TBaseFieldValue >>
    )[childField.name];
    return baseFieldHasValue(fieldValue as TBaseFieldValue);
  };

  const fieldPassesValidators = (field: IField) => {
    if (!FIELD_VALIDATOR_MAP[field.name]) return true;
    let passesValidation = true;
    for (const validator of FIELD_VALIDATOR_MAP[field.name] as TValidator[]) {
      if (!FIELD_VALIDATORS[validator](formStore[0]?.[field.name])) {
        passesValidation = false;
        break;
      }
    }
    return passesValidation;
  };

  const childFieldPassesValidators = (field: IField, childField: IChildField) => {
    if (!FIELD_CHILD_VALIDATOR_MAP[field.name]?.[childField.name]) return true;
    let passesValidation = true;
    for (const validator of FIELD_CHILD_VALIDATOR_MAP[field.name]?.[childField.name] as TValidator[]) {
      if (!FIELD_VALIDATORS[validator](
        (
          formStore[0][field.name] as Partial<Record<
          TChildFieldName,
          TBaseFieldValue>
          >)?.[childField.name],
      )) {
        passesValidation = false;
        break;
      }
    }
    return passesValidation;
  };

  const isSectionValid = (section: ISection) => {
    let isValid = true;
    for (const field of getSectionFields(section)) {
      if (showField(field)) {
        if ((field.required && !fieldHasValue(field)) || !fieldPassesValidators(field)) {
          isValid = false;
          break;
        }
        if (field.children) {
          for (const childField of field.children) {
            if (showField(childField)) {
              if ((
                childField.required &&
                !childFieldHasValue(field, childField)) ||
                !childFieldPassesValidators(field, childField)
              ) {
                isValid = false;
                break;
              }
            }
          }
        }
      }
    }
    return isValid;
  };

  // This is way more complicated than it needs to be because in the current form service numbers are stored as strings
  // I've done the same in this component but maybe something we can look at changing
  // This should probably return the array itself and map the linkingIDs, default 0, 1, 2 etc
  const getFieldsetRepeater = (fieldset: IFieldset): string[] | number[] => {
    if (!fieldset.repeatOn) return [ 0 ];
    const repeatOnValue = formStore[0]?.[fieldset.repeatOn];
    if (!repeatOnValue) return [ 0 ];
    if (typeof repeatOnValue === "string" && Number.isNaN(+repeatOnValue)) return [ 0 ];
    if (Array.isArray(repeatOnValue)) return repeatOnValue as [];
    return Array.from(Array(Number(repeatOnValue)).keys());
  };

  const toSubmissionFieldValues = (store: TFieldStore): SubmissionFieldValues => {
    const linkingId = 0;
    const submissionFieldValues: SubmissionFieldValues = new Map();

    const getPrimaryReason = getActiveFieldValue(flowSlug, "cancellationPrimary", "0");
    const getSecondaryReason = getActiveFieldValue(flowSlug, "cancellationSecondary", "0");
    const getTertiaryReason = getActiveFieldValue(flowSlug, "cancellationTertiary", "0");
    const subscriptionId = getActiveFieldValue(flowSlug, "freshMealPlanId", "0");

    if (getPrimaryReason?.data.submitValue) {
      submissionFieldValues.set("primaryReason" as FieldName, {
        value: getPrimaryReason.data.submitValue,
        linkingId: `${linkingId}`,
      });
    }
    if (getSecondaryReason?.data.submitValue) {
      submissionFieldValues.set("secondaryReason" as FieldName, {
        value: getSecondaryReason.data.submitValue,
        linkingId: `${linkingId}`,
      });
    }
    if (getTertiaryReason?.data.submitValue) {
      submissionFieldValues.set("freeText" as FieldName, {
        value: getTertiaryReason.data.submitValue,
        linkingId: `${linkingId}`,
      });
    }
    if (subscriptionId?.data.submitValue) {
      submissionFieldValues.set("subscriptionId" as FieldName, {
        value: Number(subscriptionId.data.submitValue),
        linkingId: `${linkingId}`,
      });
    }
    Object.entries(store[linkingId]).filter(([ key ]) => key !== "categoryScoring").forEach(([ key, value ]) => {
      submissionFieldValues.set(key as FieldName, {
        value: key === "score" ? Number(value) : value as FieldValue,
        linkingId: `${linkingId}`,
      });
    });

    if (store[linkingId].categoryScoring) {
      Object.entries(store[linkingId].categoryScoring).forEach(([ key, value ]) => {
        submissionFieldValues.set(key as FieldName, {
          value: value as FieldValue,
          linkingId: `${linkingId}`,
        });
      });
    }

    return submissionFieldValues;
  };

  const onCancelSubscription = async () => {
    setButtonState(DisplayState.PROCESSING);
    const submissionFieldValues = toSubmissionFieldValues(formStore);
    const featureFlags = posthog.featureFlags.getFlagVariants();
    const catName = getActiveFieldValue(flowSlug, "catName", "0");

    try {
      const result = await formsActionsAPI.performAction(
        "cancelFreshPlan",
        submissionFieldValues,
        "FRESH",
        flowSlug,
        undefined,
        featureFlags,
        getActionTrackingInfo(cookies),
      );
      if (result.success) {
        setButtonState(DisplayState.COMPLETE);
        router.push(`/cancelled/fresh?catName=${catName?.data.displayValue}`);
      } else {
        setButtonState(DisplayState.ERROR);
        console.error("Could not cancel subscription", result);
      }
    } catch (e) {
      setButtonState(DisplayState.ERROR);
      console.error("Unhandled exception when cancelling subscription", e);
    }
  };

  return (
    <Column>
      { SURVEYS[survey].forms.map((form) => (
        form.sections.map((section) => (
          <>
            { section.fieldsets.map((fieldset) => (
              /* eslint-disable-next-line */
              getFieldsetRepeater(fieldset).map((linkingId) => (
                fieldset.fields.map((field) => (
                  <>
                    { showField(field) && (
                      <>
                        <Component
                          key={field.name}
                          field={{
                            ...field,
                            optional: !field.required,
                            children: field.children?.filter((child) => showField(child)),
                          }}
                          value={formStore[0]?.[field.name]}
                          onChange={(event) => updateFormStoreValue(
                            0,
                            field.name,
                            event.target.value,
                            event.target.name as TFieldName | TChildFieldName,
                          )}
                        />
                        <Spacer size="md" />
                      </>
                    ) }
                  </>
                ))
              ))
            )) }
            <Grid>
              <Column align="center" justify="center">
                <Button
                  onClick={onCancelSubscription}
                  type="button"
                  state={buttonState}
                  disabled={!isSectionValid(section)}
                >
                    Cancel my subscription
                </Button>
              </Column>
            </Grid>
          </>
        ))
      )) }
    </Column>
  );
};
