"use client";

import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
} from "react";

interface Props {
  value: boolean;
  onChange(_: boolean): void;
}

export const Checkbox: FC<Props> = ({
  value,
  onChange,
}) => {

  const handler: ChangeEventHandler<HTMLInputElement> = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  }, [ onChange ]);

  return (
    <input
      className="rounded-sm accent-indigo-600"
      checked={!!value}
      onChange={handler}
      type="checkbox"
    />
  );
};
