"use client";

import clsx from "clsx";
import { FC } from "react";

interface Props {
  type?: string;
  value?: string;
  onChange?: (_: string) => void;
  placeholder?: string;
  className?: string;
  containerClass?: string;
  disabled?: boolean;
}
export const TextInput: FC<Props> = ({
  value,
  onChange = () => {},
  placeholder = "",
  className = "",
  type = "text",
  containerClass = "",
  disabled = false,
}) => (
  <div data-component={TextInput.name} className={containerClass}>
    <div className={clsx(
      "relative bg-white flex items-center rounded-md border border-gray-300 shadow-sm focus-within:border-indigo-500 sm:text-sm",
      disabled && "bg-gray-100",
    )}>
      <input
        disabled={disabled}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className={clsx(
          "placeholder-gray-300 flex-1 border-none appearance-none focus:ring-0 bg-transparent",
          className,
          disabled && "text-gray-400 cursor-not-allowed",
        )}
      />
    </div>
  </div>
);
