import { getStoryblokApi } from "@storyblok/react";
import * as CacheManager from "cache-manager";

import {
  IStoryBlokAnnouncementBanner,
  IStoryBlokCookieBar,
  IStoryBlokFooter,
  IStoryBlokHeader,
  IStoryBlokNewsletterSignUpModal,
  IStoryBlokSeo,
} from "../components/page/api.types";
import { IStoryBlokArticleMain } from "../types/article/IStoryBlokArticleMain";
import { RevalidationTime } from "../constants/revalidation";

import { ARTICLE_RELATIONS, sbParams } from "./config";

class StoryblokClient {

  private readonly api = getStoryblokApi();

  private readonly cachePromise = CacheManager.caching("memory", {
    ttl: RevalidationTime.STANDARD,
  });

  public async getDefaultHeader() {
    const header: IStoryBlokHeader = await this.getStoryContent("reusable/page/header");
    return header;
  }

  public async getDefaultFooter() {
    const footer: IStoryBlokFooter = await this.getStoryContent("reusable/page/footer");
    return footer;
  }

  public async getDefaultCookieBar() {
    const cookieBar: IStoryBlokCookieBar = await this.getStoryContent("reusable/page/cookie-bar");
    return cookieBar;
  }

  public async getDefaultAnnouncementBanner() {
    const cookieBar: IStoryBlokAnnouncementBanner = await this.getStoryContent("reusable/page/announcement-banner");
    return cookieBar;
  }

  public async getFallbackHeaderTags() {
    const fallbackTags: IStoryBlokSeo = await this.getStoryContent("globals/default-header-tags");
    return fallbackTags;
  }

  public async getNewsletterForm() {
    const newsletterSignupModal: IStoryBlokNewsletterSignUpModal = await this.getStoryContent("reusable/page/newsletter-form");
    return newsletterSignupModal;
  }

  public async getBlogPosts(page = 1) {
    const response = await this.api?.get("cdn/stories?starts_with=articles/posts/&filter_query[component][not_in]=author,category", {
      ...sbParams,
      resolve_relations: ARTICLE_RELATIONS,
      per_page: 12,
      page,
    });

    const articles = response.data.stories as IStoryBlokArticleMain[];

    return {
      articles,
      total: response.total,
      perPage: response.perPage,
    };
  }

  private async getStoryContent(path: string, disableCache = false) {

    const cache = await this.cachePromise;

    if (!disableCache) {
      const cacheResult = await cache.get(path);
      if (cacheResult) return cacheResult;
    }

    const response = await this.api?.get(`cdn/stories/${path}`, sbParams);
    if (!response?.data?.story) return;
    const { content } = response.data.story;

    if (!disableCache) {
      await cache.set(path, content);
    }

    return content;
  }

}

export const SBHelper = new StoryblokClient();
