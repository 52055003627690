import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {
  FC,
  FormEventHandler,
  useEffect,
  useState,
} from "react";

import Button from "apps/website/components/base/Button/Button";
import Icon from "apps/website/components/base/Icon/Icon";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Form from "apps/website/components/form/Form/Form";
import { inputStyles } from "apps/website/components/form/Input/Input";
import Label from "apps/website/components/form/Label/Label";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image from "apps/website/components/base/Image/Image";
import { DisplayState } from "@/constants/state";
import FormError from "apps/website/components/form-service/fields/Error";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

const cardInputClasses = `${inputStyles} flex items-center cursor-text [&>*]:w-full [&>*]:font-display`;
const cardInputOptions = { style: { base: { fontFamily: "GreedBold, sans-serif", fontWeight: "900", fontSize: "20px" } } };

export interface ICardDetailsForm {
  isDisabled: boolean;
  onSubmit(setupIntentId: string): void;
  onCancel(): void;
  setupIntentClientSecret: string;
}

const CardDetailsForm: FC<ICardDetailsForm> = ({ onSubmit, isDisabled, onCancel, setupIntentClientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [ error, setError ] = useState<string | undefined>(undefined);
  const [ isCardNumberComplete, setIsCardNumberComplete ] = useState<boolean>(false);
  const [ isCVCComplete, setIsCVCComplete ] = useState<boolean>(false);
  const [ isExpiryComplete, setIsExpiryComplete ] = useState<boolean>(false);
  const [ isCardDetailsComplete, setIsCardDetailsComplete ] = useState<boolean>(false);
  const [ paymentState, setPaymentState ] = useState<DisplayState>(DisplayState.READY);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    setPaymentState(DisplayState.PROCESSING);
    try {
      const cardElement = elements?.getElement("cardNumber");
      if (!cardElement) {
        console.error("missing card number on payment details submission.");
        setError("Missing card number");
        setPaymentState(DisplayState.ERROR);
        return;
      }
      const intent = await stripe?.confirmCardSetup(setupIntentClientSecret, {
        payment_method: {
          card: cardElement,
        },
      });
      if (!intent?.setupIntent) {
        console.error("Setup intent failed.");
        setError("Setup intent failed");
        setPaymentState(DisplayState.ERROR);
        if (intent?.error) {
          setError(intent?.error.message);
        }
        return;
      }
      setError(undefined);
      onSubmit(intent.setupIntent?.id);
      setPaymentState(DisplayState.PROCESSING);
    } catch (err) {
      if (err instanceof Error) {
        setError(err?.message);
      }
      setPaymentState(DisplayState.ERROR);
    }
  };

  useEffect(() => {
    setIsCardDetailsComplete(isCardNumberComplete && isCVCComplete && isExpiryComplete);
  }, [ isCardNumberComplete, isCVCComplete, isExpiryComplete ]);

  return (
    <>
      <Form action="" onSubmit={handleSubmit}>
        <fieldset>
          <Grid>
            <Column>
              <legend className="flex justify-between">
                <Text tag="h2" size={legacySizeCollectionMap.titleSm} display="subtitle">Payment details</Text>
                <div className="flex">
                  <div className="max-w-[30px] flex items-center justify-center mr-2">
                    <Image image={{ src: "/images/checkout/visa.svg", width: 30, height: 30 }} alt="Visa" />
                  </div>
                  <div className="max-w-[36px] flex items-center justify-center mr-2">
                    <Image image={{ src: "/images/checkout/mastercard.png", width: 1200, height: 720 }} alt="Mastercard" />
                  </div>
                  <div className="max-w-[30px] flex items-center justify-center">
                    <Image image={{ src: "/images/checkout/amex.svg", width: 30, height: 30 }} alt="American Express" />
                  </div>
                </div>
              </legend>
            </Column>
            <Column>
              <Label label="Card number" hideLabel={false} UUID="cardNumber" fontStyle="alternative">
                <CardNumberElement
                  className={cardInputClasses}
                  options={cardInputOptions}
                  onChange={(event) => setIsCardNumberComplete(event.complete)}
                />
              </Label>
            </Column>
            <Column spans={6}>
              <Label label="CVC" hideLabel={false} UUID="cardCvc" fontStyle="alternative">
                <CardCvcElement
                  className={cardInputClasses}
                  options={cardInputOptions}
                  onChange={(event) => setIsCVCComplete(event.complete)}
                />
              </Label>
            </Column>
            <Column spans={6}>
              <Label label="Expiry" hideLabel={false} UUID="cardExpiry" fontStyle="alternative">
                <CardExpiryElement
                  className={cardInputClasses}
                  options={cardInputOptions}
                  onChange={(event) => setIsExpiryComplete(event.complete)}
                />
              </Label>
            </Column>
            <>
              { error && (
                <Column >
                  <FormError>{ error }</FormError>
                </Column>
              ) }
            </>
          </Grid>
        </fieldset>
        <Spacer size="xl" />
        <Grid>
          <Column spans={4} offset={{ lg: 3 }} align={"end"}>
            <Button onClick={onCancel} design="underline" >
                Cancel
            </Button>
          </Column>
          <Column spans={{ default: 8, lg: 4 }} align={"start"}>
            <Button type="submit" disabled={!stripe || isDisabled || !isCardDetailsComplete} state={paymentState}>
              <span className="flex justify-center items-center"><Icon icon="padlock" color="inherit" size="xsmall" /><span className="ml-2">Confirm</span></span>
            </Button>
          </Column>
        </Grid>
      </Form>
    </>
  );
};

export default CardDetailsForm;
